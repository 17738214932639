import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';
import Scrollbar from 'smooth-scrollbar';

const scrollbar = Scrollbar.init(document.querySelector('#main-scrollbar'));

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on the about us page

    const numberOfStats = $('.about-section.statistics-section .stat-item-wrp').length;
    let counterArray = [];
    for (let index = 0; index < numberOfStats; index++) {
      counterArray.push(0);
    }

    var observer = new IntersectionObserver(function(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          if (counterArray[entry.target.getAttribute('data-stat')] == 0) {
            setTimeout(function(){
              counterArray[entry.target.getAttribute('data-stat')]++;
              document.querySelector(`#final-${parseInt(entry.target.getAttribute('data-stat'))+1}`).classList.remove('d-none');
              document.querySelector(`#initial-${parseInt(entry.target.getAttribute('data-stat'))+1}`).classList.add('d-none');
              countUp(parseInt(entry.target.getAttribute('data-stat'))+1);
            }, 200);
          }
        }
      });
    }, { threshold: [1] });

    let elements = document.querySelectorAll('.about-section.statistics-section .stat-item-wrp .stat-item');

    elements.forEach((el) => {
      observer.observe(el);
    });

    bannerSlider();
    aboutUsGallery();
    teamSectionAll()
  },
};

$(window).on('resize', function () {
  $('.about-section.banner-section .banner-wrp, .about-us-section .gallery-wrp').flickity('resize');
  teamSectionAll()
});

$(window).on('load', function () {
  $('.about-section.banner-section .banner-wrp, .about-us-section .gallery-wrp').flickity('resize');
  teamSectionAll()
});

scrollbar.addListener(() => {
  teamSectionAll()
});

function countUp(target) {
  $(`#final-${target}`).each(function () {
    $(this)
      .prop('Counter', 0)
      .animate(
        {
          Counter: $(this).text(),
        },
        {
          duration: 2000,
          easing: 'swing',
          step: function (now) {
            now = Number(Math.ceil(now)).toLocaleString('en');
            $(this).text(now);
          },
        }
      );
    }
  );
}

function bannerSlider() {
  var Flickity = require('flickity')
  var FlickityTransformer = require('flickity-transformer')

  var flkty = new Flickity('.about-section.banner-section .banner-wrp', {
    pageDots: false,
    prevNextButtons: false,
    wrapAround: false,
    cellAlign: 'center',
    lazyLoad: true,
    draggable: false,
    initialIndex: 12,
  })

  new FlickityTransformer(flkty, [
    {
      name: 'translateY',
      stops: [
        [-1000, 170],
        [-600, 50],
        [0, 0],
        [600, 50],
        [1000, 170],
      ],
    },
    {
      name: 'rotate',
      stops: [
        [-1000, -20],
        [-600, -10],
        [0, 0],
        [600, 10],
        [1000, 20],
      ],
    },
    // {
    //   // Declare perspective here, before rotateY. At least two stops are required, hence the duplication.
    //   name: 'perspective',
    //   stops: [
    //     [0, 600],
    //     [1, 300],
    //   ],
    // },
    // {
    //   name: 'rotateY',
    //   stops: [
    //     [-300, 0],
    //     [0, 0],
    //     [300, 0],
    //   ],
    // },
  ]);

  let lastScroll = 0;
  // window.addEventListener('scroll', onScroll, false)

  scrollbar.addListener(() => {
    onScroll();
  });

  function onScroll() {
    flkty.isFreeScrolling = true;
    flkty.startAnimation();
    const scrollY = scrollbar.offset.y;
    let diff = scrollY - lastScroll
    lastScroll = scrollY;

    flkty.velocity += diff * -0.05;
    flkty.x += flkty.velocity;
    flkty.velocity *= 1 - 0.03;

    // let previousX = flkty.x
    flkty.positionSlider();
    //flick.selectedIndex = flick.dragEndRestingSelect()
    //flick.updateSelectedSlide()
    //flick.settle( previousX );
  }
}

function aboutUsGallery() {
  $('.about-us-section .gallery-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    autoPlay: 5500,
    draggable: true,
  });
}

function teamSectionScroll() {
  function easeOutCubic(t) {
    return 1 - Math.pow(1 - t, 3);
  }

  var leftItems = $('.team-scroll-items');
  var divHeight = leftItems.outerHeight();

  if (leftItems.length) {
    var divBottom = $(window).height() - leftItems.offset().top;
  }

  if (divBottom > 0 && divHeight > divBottom) {
    var scrollHeight = leftItems.outerHeight() - divBottom;
    var scrollPercentage = scrollHeight/divHeight;
    var easedScrollPercentage = easeOutCubic(scrollPercentage);
    var translateY;

    if (matchMedia('screen and (max-width: 1400px)').matches) {
      $(leftItems).css('margin-top', 521);
      translateY = easedScrollPercentage * 521;

    } else {
      $(leftItems).css('margin-top', 697);
      translateY = easedScrollPercentage * 697;
    }
    leftItems.css('transform', 'translateY(-' + translateY + 'px)');
  }
}

function isElementCentered($element) {
  var elementTop = $element.offset().top;
  var elementHeight = $element.outerHeight();
  var elementCenter = elementTop + (elementHeight / 2);

  var viewportHeight = $(window).height();
  var viewportCenter = (viewportHeight / 2);

  return Math.abs(elementCenter - viewportCenter) < 200;
}

function checkElements() {
  $('.mobile-items .card-wrapper').each(function() {
    if (isElementCentered($(this))) {
      $(this).addClass('centered');
    } else {
      $(this).removeClass('centered');
    }
  });
}

function teamSectionAll() {
  if (matchMedia('screen and (max-width: 767px)').matches) {
    checkElements();
  } else if (matchMedia('screen and (min-width: 1200px)').matches) {
    teamSectionScroll();
  }
}