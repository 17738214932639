import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on the about us page
    caseStudiesSlider();
  },
};

$(window).on('resize', function () {
  $('.services-inner-wrp .images-wrp').flickity('resize');
});

$(window).on('load', function () {
  $('.services-inner-wrp .images-wrp').flickity('resize');
});

function  caseStudiesSlider() {
  var cellAlignValue = '';
  var count = $('.services-inner-wrp .images-wrp').length;

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  for (let i = 1; i <= count; i++) {
    $('.services-inner-wrp .images-wrp').eq(i - 1).flickity({
      autoPlay: 3000 + i * 100,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      draggable: true,
      cellAlign: cellAlignValue,
    });
  }
}